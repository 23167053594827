import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from 'ng-zorro-antd/table';

export interface Menu {
  name: string;
  icon: string;
  permission: string;
  url: string;
  children?: SubMenu[];
}

export interface SubMenu {
  name: string;
  permission: string;
  url: string;
}

export interface Breadcrumb {
  title: string;
  route?: string;
  icon?: string;
}

export interface Country {
  name: string;
  countryId: string;
  currencyId: string;
  langId: string;
  currencyLabel: string;
  currencySymbol: string;
  locale: string;
}

export interface DateRangePicker {
  start: number;
  end: number;
}

export interface BasicDropdownOption {
  label: string;
  value: string | number;
}

export interface PortalPostResponse {
  code: string;
  status: number;
  timestamp: number;
}
export interface PortalFetchResponse<T> {
  code: string;
  data: T;
  status: number;
  timestamp: number;
}

export interface PortalTableResponse<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: any;
  size: number;
  sort: any;
  totalElements: number;
  totalPages: number;
}

export interface LoyaltyFetchResponse<T> {
  code: string;
  requestID: string;
  data: T;
  status: number;
  timestamp: number;
}

export type SortDirection = 'ASC' | 'DESC';

export interface ColumnItem<T> {
  name: string;
  key: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<T> | null | boolean;
  listOfFilter?: NzTableFilterList;
  filterFn?: NzTableFilterFn<T> | null;
  filterMultiple?: boolean;
  sortDirections: NzTableSortOrder[];
  class: string[];
  sortPriority?: boolean | number;
}

export interface ITranslationVariable {
  [key: string]: string;
}

export interface INoResultLabel {
  withoutFilter: string;
  search: string;
  date: string;
}

export interface ITableSort {
  direction?: SortDirection;
  field?: string;
}

export interface ICheckboxOption {
  label: string;
  value: string | number;
  checked?: boolean;
  disabled?: boolean;
}

export enum ILang {
  EN_US = 'en_US',
  ZH_TW = 'zh_TW',
  ZH_CN = 'zh_CN',
  ID_ID = 'id_ID',
}
